import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { SidebarProvider } from "./context/SidebarContext";
import reportWebVitals from "./reportWebVitals";
import ThemedSuspense from "./components/ThemedSuspense";
import { AuthProvider } from "./context/AuthContext";
import "../src/translations/i18n";
import StepContext from "./context/MultiStepFormContext";
import { MultiStepFormProvider } from "./context/PreviewContext";
import { DownloadProvider } from "./context/DownloadContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { CommonDataContext } from "./context/DataContext";
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
const queryClient = new QueryClient();
const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(document.getElementById("root"));
const THEME = createTheme({
  components: {
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiDrawer: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiDialog: {
      defaultProps: {
        container: rootElement,
      },
    },
  },
});
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <SidebarProvider>
        <AuthProvider>
          <MultiStepFormProvider>
            <DownloadProvider>
              <CommonDataContext>
                <ThemeProvider theme={THEME}>
                  <Suspense fallback={<ThemedSuspense />}>
                    <StepContext>
                      <App />
                    </StepContext>
                  </Suspense>
                </ThemeProvider>
              </CommonDataContext>
            </DownloadProvider>
          </MultiStepFormProvider>
        </AuthProvider>
      </SidebarProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
