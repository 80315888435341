// PreviewContext.js
import React, { createContext, useState } from "react";

export const PreviewContext = createContext();

export const MultiStepFormProvider = ({ children }) => {
  const [panelFormStepThreeResponse, setPanelFormStepThreeResponse] = useState(
    []
  );
  // console.log("panelFormStepThreeResponse", panelFormStepThreeResponse);

  return (
    <PreviewContext.Provider
      value={{ panelFormStepThreeResponse, setPanelFormStepThreeResponse }}
    >
      {children}
    </PreviewContext.Provider>
  );
};
