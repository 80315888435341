import React, { useContext } from "react";
import { Formik, Form, Field } from "formik";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../context/AuthContext";
import { DataContext } from "../../context/DataContext";
import { useTimer } from "react-timer-hook";

const OtpVerificationForm = () => {
  const { email, password } = useContext(DataContext);
  const { otpVerificationCall, login } = useContext(AuthContext);
  const { t } = useTranslation();

  var style = {
    asterisk: {
      color: "red",
      marginLeft: "3px",
    },
    fieldstyle:
      "form-control  w-full mt-2 text-sm pl-5 text-black bg-white bg-clip-padding border border-solid border-Bittersweet rounded transition ease-in-out  m-0 inline-block h-12  font-semibold ",
  };

  const handleResendClick = () => {
    login(email, password)
      .then(() => {
        //console.log("OTP verify called");
        restartTimer(new Date().setSeconds(new Date().getSeconds() + 60));
      })
      .catch((error) => {
        //console.log("forgot password error", error);
      });
  };

  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 60);
  const {
    seconds,
    isRunning,
    restart: restartTimer,
  } = useTimer({
    expiryTimestamp,
  });

  // let mobile = window.localStorage.getItem("mobile") || "";

  return (
    <Formik
      initialValues={{
        otp: "",
      }}
      onSubmit={({ otp }, { setStatus, setSubmitting }) => {
        setSubmitting(true);
        setStatus();
        otpVerificationCall(email, otp)
          .then(() => {
            // setShowSuccess(true);
            //console.log("OTP verify called");
          })
          .catch((error) => {
            //console.log("forgot password error");
            if (error.response) {
              setStatus(error.response.data.message);
            } else {
              setStatus("Some error occurred. Please try again.");
            }
            setSubmitting(false);
          });
      }}
    >
      {({ errors, status, touched, isSubmitting, setFieldValue }) => (
        <Form>
          <label className="w-full">
            <span>OTP</span>
            <span style={style.asterisk}>*</span>
            <div className="relative">
              <Field
                className={style.fieldstyle}
                as={"input"}
                name="otp"
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                placeholder="Enter the OTP you received in your registered mail ID"
              />
            </div>
          </label>
          <div className="flex justify-between font-normal text-xs mt-5 w-full mb-3">
            <div>
              <span className="font-normal text-xs">Time Remaining: </span>
              <span className="text-[#4B93FF] text-sm font-semibold">
                00:{seconds < 10 ? `0${seconds}` : seconds}
              </span>
            </div>
            <div>
              <div
                onClick={handleResendClick}
                disabled={isRunning}
                className={`text-[#4B93FF] text-sm underline font-semibold  ${
                  isRunning
                    ? " text-[#D9D9D9] cursor-not-allowed"
                    : "cursor-pointer"
                }`}
              >
                {t("Resend OTP")}
              </div>
            </div>
          </div>
          <button
            className="mt-8 bg-red-500 text-white text-sm w-full py-2 rounded-md"
            block="true"
            type="submit"
            value="submit"
            style={{ height: "38px" }}
            // disabled={isSubmitting}
          >
            {t("Verify")}
          </button>
          {status && (
            <p className="text-errorColor" valid={false}>
              {status}
            </p>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default OtpVerificationForm;
