import React, { lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ForgotPassword from "../pages/ForgotPassword";
import OtpVerification from "../pages/OTPVerification";
const Login = lazy(() => import("../pages/Login"));
const ResetPassword = lazy(() => import("../pages/ResetPassword"));
function Auth() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/verify-otp" element={<OtpVerification />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/" element={<Navigate to="/auth/login" />} />
    </Routes>
  );
}

export default Auth;
