import React from "react";
import { useTranslation } from "react-i18next";

function CustomCard() {
  const { t } = useTranslation();

  return (
    <div className="flex text-center  text-base font-semibold">
      {t(
        "_We_will_send_a_verification_link_to_the_given_email_ID._Please_click_on_the_link_to_reset_your_password."
      )}
    </div>
  );
}

export default CustomCard;
