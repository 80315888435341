import React from "react";

function ThemedSuspense() {
  return (
    <div className="w-full h-screen flex justify-center items-center text-center p-6">
      {/* <CircularProgress /> */}
      <h1>Loading...</h1>
    </div>
  );
}

export default ThemedSuspense;
