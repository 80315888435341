import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/img/Airtel 1.svg";
import OTPVerificationForm from "../components/Forms/OTPVerificationForm";
import { useTranslation } from "react-i18next";
import CustomCard from "../components/Card/CustomCard";
import OtpVerificationForm from "../components/Forms/OTPVerificationForm";

// import LoginForm from '../components/Forms/LoginForm'

function OtpVerification() {
  const { t } = useTranslation();
  const [showSuccess, setShowSuccess] = useState(false);

  return (
    <div className="flex flex-col min-h-screen bg-gray-50 font-sans">
      <Link
        to="/"
        className="pl-8 pr-8 text-xl font-bold text-gray-800 dark:text-gray-200 bg-white dark:bg-white-900"
      >
        <img
          width={"150px"}
          aria-hidden="true"
          src={Logo}
          alt="Airtel SMS Hub Reporting"
        />
      </Link>
      <div className="flex flex-1 h-full items-center lg:mt-0">
        <div className="flex-1 h-full max-w-xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl ">
          <main className="flex items-center justify-center p-6 sm:p-12 md:flex-row">
            <div className="w-full">
              <h1
                className="mb-2 text-xl font-semibold text-gray-700 "
                style={{ textAlign: "center" }}
              >
                Enter OTP
                <hr />
              </h1>
              <br />

              <OTPVerificationForm />
            </div>
          </main>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}

export default OtpVerification;
