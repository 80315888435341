import React, { useContext, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";
import Button from "../Button/OutlinedButton";
import { emailValidation } from "../../common/yupValidation";
import InputLabel from "../FormsUI/InputLabel/InputLabel";

function ForgotPasswordForm({ setShowSuccess }) {
  var style = {
    fieldstyle:
      "form-control  w-full mt-2 text-sm pl-5 text-black bg-white bg-clip-padding border border-solid border-Bittersweet rounded transition ease-in-out  m-0 inline-block h-12  font-semibold ",
  };
  const { forgotPassword } = useContext(AuthContext);
  const { t } = useTranslation();
  return (
    <div>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={Yup.object().shape({
          email: emailValidation,
        })}
        onSubmit={({ email, password }, { setStatus, setSubmitting }) => {
          setSubmitting(true);
          setStatus();
          forgotPassword(email)
            .then(() => {
              setShowSuccess(true);
              //console.log("forgot password email called");
            })
            .catch((error) => {
              //console.log("forgot password error");
              if (error.response) {
                setStatus(error.response.data.message);
              } else {
                setStatus("Some error occured. Please try again.");
              }
              setSubmitting(false);
            });
        }}
      >
        {({ errors, status, touched, isSubmitting }) => (
          <Form>
            <div className="w-full">
              <InputLabel label={"Email id"} isMandatory={true} />

              <div className="relative">
                <Field
                  className={style.fieldstyle}
                  as={"input"}
                  name="email"
                  type="email"
                  placeholder="Enter the Email"
                />
                {errors.email && touched.email ? (
                  <div>
                    <p className="text-errorColor text-xs" valid={false}>
                      {errors.email}
                    </p>
                  </div>
                ) : null}
              </div>
            </div>

            <button
              className="mt-8 bg-bgSecondary text-white text-sm  w-full py-2 rounded-md"
              block="true"
              type="submit"
              value="submit"
              disabled={isSubmitting}
              style={{ height: "38px" }}
            >
              {t("Request a reset link")}
            </button>
            {status && (
              <p className="error" valid={false}>
                {status}
              </p>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ForgotPasswordForm;
