import React, { lazy, useContext } from "react";
import { Navigate } from "react-router";
import { AuthContext } from "../../context/AuthContext";
const Layout = lazy(() => import("../../containers/Layout"));

const ProtectedRoute = () => {
  const { user } = useContext(AuthContext);
  // const user = true;
  // console.log('from pe', user);
  return user ? <Layout /> : <Navigate to="/auth/login" />;
};

export default ProtectedRoute;
