import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
const ConfirmNNextButton = ({ buttonClassName, ...rest }) => {
  const { t } = useTranslation();
  return (
    <LoadingButton
      {...rest}
      style={{ textTransform: "none" }}
      className={twMerge(
        `bg-bgSecondary text-white text-medium font-normal h-10 rounded-10px disabled:bg-opacity-60 disabled:text-white ${buttonClassName}`
      )}
      variant="contained"
      // loadingIndicator={<CircularProgress className="text-white" />}
    >
      <span>{t(`${rest.label}`)}</span>
    </LoadingButton>
  );
};

export default ConfirmNNextButton;
