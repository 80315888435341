import React from "react";
import { useTranslation } from "react-i18next";

const InputLabel = ({ label, color, labelClassName, isMandatory }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`${
        color ? color : "text-headingColor"
      }  text-sm mb-1 ${labelClassName}`}
    >
      {t(label)}
      {isMandatory ? <span className="text-errorColor text-sm"> *</span> : null}
    </div>
  );
};

export default InputLabel;
