import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import Auth from "../../containers/Auth";
import { AuthContext } from "../../context/AuthContext";

export default function ForwardRoute() {
  const { user } = useContext(AuthContext);
  //console.log("!user.isEmailVerified", !user.isEmailVerified);
  const hasDefaultDashboard =
    user?.role?.resources?.hasOwnProperty("Default Dashboard");
  // console.log("hasDefaultDashboard", hasDefaultDashboard);
  return (
    <>
      {user ? (
        !user?.isEmailVerified ? (
          <Navigate to="/app/home" />
        ) : !hasDefaultDashboard ? (
          <Navigate to="/app/dashboard/details" />
        ) : hasDefaultDashboard ? (
          <Navigate to="/app/home" />
        ) : (
          <Auth />
        )
      ) : (
        <Auth />
      )}
    </>
  );
}
