import React, { useState } from "react";

export const multiStepFormContext = React.createContext();
const StepContext = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [stepCount, setStepCount] = useState(0);
  const [stepsCompleted, setStepsCompleted] = useState([]);
  const [formData, setFormData] = useState([]);
  const [finalData, setFinalData] = useState([]);

  const handleNextClick = (increment) => {
    let jumpNextStepBy = increment || 1;
    setCurrentStep(currentStep + jumpNextStepBy);
    if (jumpNextStepBy > 1) {
      stepsCompleted.splice(currentStep - 1, 5, currentStep);
    } else {
      if (!stepsCompleted.some((x) => x === currentStep))
        setStepsCompleted([...stepsCompleted, currentStep]);
    }
  };

  const handleNextClickStep = (increment) => {
    let jumpNextStepBy = increment || 1;
    setStepCount(stepCount + jumpNextStepBy);
    if (jumpNextStepBy > 1) {
      stepsCompleted.splice(currentStep - 1, 5, currentStep);
    } else {
      if (!stepsCompleted.some((x) => x === currentStep))
        setStepsCompleted([...stepsCompleted, currentStep]);
    }
  };

  const handleCancelClick = () => {
    setCurrentStep(1);
    setStepsCompleted([]);
    setFormData([]);
    setFinalData([]);
  };
  const handlePrevClick = () => {
    let index = stepsCompleted.indexOf(currentStep);
    if (currentStep !== 0) {
      if (index === -1) {
        setCurrentStep(stepsCompleted[stepsCompleted.length - 1]);
      } else if (index === 0) {
        setCurrentStep(0);
      } else {
        setCurrentStep(stepsCompleted[index - 1]);
      }
    }
  };

  const handlePrevStep = () => {
    let index = stepsCompleted.indexOf(stepCount);
    if (stepCount !== 0) {
      if (index === -1) {
        setStepCount(stepsCompleted[stepsCompleted.length - 1]);
      } else if (index === 0) {
        setStepCount(0);
      } else {
        setStepCount(stepsCompleted[index - 1]);
      }
    }
  };

  return (
    <div>
      <multiStepFormContext.Provider
        value={{
          currentStep,
          setCurrentStep,
          stepsCompleted,
          setStepsCompleted,
          formData,
          setFormData,
          finalData,
          setFinalData,
          handleNextClick,
          handlePrevClick,
          handleCancelClick,
          stepCount,
          setStepCount,
          handleNextClickStep,
          handlePrevStep,
        }}
      >
        {children}
      </multiStepFormContext.Provider>
    </div>
  );
};
export default StepContext;
