import React, { createContext, useState } from "react";
import { Box, LinearProgress } from "@mui/material";

export const DownloadContext = createContext();

export const DownloadProvider = ({ children }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const getMessage = () => {
    if (isDownloading) return "Downloading ...";
    if (isSaving) return "Report is getting saved in configured path ...";
    return "";
  };

  const message = getMessage();

  return (
    <DownloadContext.Provider
      value={{ isDownloading, setIsDownloading, isSaving, setIsSaving }}
    >
      {children}
      {isDownloading || isSaving ? (
        <Box
          sx={{
            width: "30%",
            position: "fixed",
            top: 0,
            right: 0,
            zIndex: 9999,
          }}
        >
          <div className="border border-panelBorder p-3 bg-white">
            <div className="text-medium font-medium text-black my-2">
              {message}
            </div>
            <LinearProgress variant="indeterminate" />
          </div>
        </Box>
      ) : null}
    </DownloadContext.Provider>
  );
};
