// PreviewContext.js
import React, { createContext, useContext, useState } from "react";
import { AuthContext } from "./AuthContext";

export const DataContext = createContext();

export const CommonDataContext = ({ children }) => {
  // const [resultPerPage, setResultPerPage] = useState([
  //   { name: "5", values: "5" },
  //   { name: "10", values: "10" },
  //   { name: "25", values: "25" },
  //   { name: "50", values: "50" },
  //   { name: "75", values: "75" },
  //   { name: "100", values: "100" },
  // ]);
  const { configApiData } = useContext(AuthContext);
  const [resultPerPage, setResultPerPage] = useState(
    Array.from({ length: configApiData?.MAX_PAGE_SIZE }, (_, i) => ({
      //configApiData?.MAX_PAGE_SIZE
      name: (i + 1).toString(),
      values: (i + 1).toString(),
    }))
  );
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visTypeChange, setVisTypeChange] = useState(false);
  const [customerListData, setCustomerListData] = useState([]);
  const [customerBindList, setCustomerBindList] = useState([]);
  const [supplierBindList, setSupplierBindList] = useState([]);
  const [optionDataList, setOptionDataList] = useState([]);
  const [destnationNameList, setDestinationNameList] = useState([]);
  const [destinationCountryList, setDestinationCountryList] = useState([]);
  const [previousValue, setPreviousValue] = useState(false);
  return (
    <DataContext.Provider
      value={{
        resultPerPage,
        setResultPerPage,
        email,
        setEmail,
        password,
        setPassword,
        visTypeChange,
        setVisTypeChange,
        customerListData,
        setCustomerListData,
        optionDataList,
        setOptionDataList,
        destnationNameList,
        setDestinationNameList,
        destinationCountryList,
        setDestinationCountryList,
        customerBindList,
        setCustomerBindList,
        supplierBindList,
        setSupplierBindList,
        setPreviousValue,
        previousValue,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
